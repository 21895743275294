<template>
  <v-row>
    <v-col cols="12" class="px-4">
      <div class="mb-8">
        <h2 class="custom-title-secondary mb-1">
          Quais CNAEs (códigos de atividades) estão cadastrados na sua empresa?
        </h2>
        <p class="custom-normal-text">
          Se não se lembrar, é só voltar lá no seu cartão CNPJ.
        </p>
      </div>
      <base-select-cnea
        :rules="rules"
        title="CNAE Primário"
        v-model="formUser.mainCnae"
        :selectedCneas="selectedCneas"
      />

      <base-select-cnea
        v-for="(item, index) in formUser.secondaryCnae"
        :key="index"
        :rules="rules"
        title="CNAE Secundário"
        v-model="formUser.secondaryCnae[index]"
        :canDelete="true"
        @delete="formUser.secondaryCnae.splice(index, 1)"
        :selectedCneas="selectedCneas"
      />
    </v-col>
    <v-col class="my-4" align-self="center">
      <div class="d-flex justify-center flex-column align-center">
        <v-btn
          text
          outlined
          class="mb-3"
          @click="addSecondaryCnae"
          :disabled="formUser.mainCnae == null"
        >
          Adicionar CNAE
          <v-icon class="ml-3" color="green">
            add
          </v-icon>
        </v-btn>
        <p>Adicione mais CNAEs, caso tenha mais algum código registrado.</p>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    answer: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rules: [(v) => !!v || 'Selecione um CNAE'],
    }
  },
  computed: {
    ...mapState({
      formUser: (state) => state.formDataUser.formUser,
    }),
    selectedCneas() {
      return [this.formUser.mainCnae, ...this.formUser.secondaryCnae]
    },
  },
  methods: {
    onAnswer() {
      let data = {
        cnaeCode: this.formUser.mainCnae?.codeWithMask,
        subCnae: this.formUser.secondaryCnae.reduce((accumulator, item) => {
          if (item) accumulator.push(item?.codeWithMask)
          return accumulator
        }, []),
      }
      this.$emit('onAnswer', data)
    },
    addSecondaryCnae() {
      this.formUser.secondaryCnae.push({ code: null })
    },
  },
  watch: {
    'formUser.secondaryCnae': {
      deep: true,
      handler(newValue, oldValue) {
        this.onAnswer()
      },
    },
    'formUser.mainCnae': {
      deep: true,
      handler(newValue, oldValue) {
        this.onAnswer()
      },
    },
  },
}
</script>
<style module lang="scss">
@import '@design';
</style>
